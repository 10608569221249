import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import TextRaw from '../text/TextRaw';
import { capitalize } from '../../../helpers/str';

class AccordionSection extends PureComponent {

  constructor(props, context) {
    super(props, context);
    this.state = { open: props.open };
    this.onToggleHandler = this.onToggleHandler.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ open: nextProps.open });
  }

  onToggleHandler() {
    const { onClick } = this.props;
    const open = !this.state.open;

    this.setState({ open });

    if (onClick) {
      onClick(open);
    }
  }

  render() {
    const { open } = this.state;
    const {
      theme,
      label,
      embedded,
      underlined,
      centered,
      plusMinusIcons,
      onContentClick,
      toggleContent,
      children,
      utilities,
      headlineInvisible,
      headlessStyle,
      arrowLeft,
      highlighted,
    } = this.props;
    return (
      <div
        className={suitcss({
          states: [open && 'open'],
          modifiers: [
            theme && `theme${capitalize(theme)}`,
            embedded && 'embedded',
            underlined && 'underlined',
            centered && 'centered',
            plusMinusIcons && 'plusMinusIcons',
            headlineInvisible && 'headlineInvisible',
            headlessStyle && 'headlessStyle',
            arrowLeft && 'arrowLeft',
            highlighted && 'highlighted',
          ],
          utilities,
        }, this)}
      >
        <button
          className={suitcss({
            descendantName: 'title',
          }, this)}
          onClick={this.onToggleHandler}
          type={'button'}
        >
          <TextRaw
            element="div"
            className={suitcss({
              descendantName: 'label',
            }, this)}
          >
            {label}
          </TextRaw>
        </button>
        {(!toggleContent || (toggleContent && open)) && (
          <div
            className={suitcss({
              descendantName: 'content',
            }, this)}
            onClick={onContentClick}
          >
            <div
              className={suitcss({
                descendantName: 'inner',
              }, this)}
            >
              {children}
            </div>
          </div>
        )}
      </div>
    );
  }
}

AccordionSection.propTypes = {
  theme: PropTypes.oneOf(['dark', 'medium', 'light', 'primary']),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onContentClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  open: PropTypes.bool,
  toggleContent: PropTypes.bool,
  embedded: PropTypes.bool,
  underlined: PropTypes.bool,
  centered: PropTypes.bool,
  plusMinusIcons: PropTypes.bool,
  utilities: PropTypes.array,
  headlineInvisible: PropTypes.bool,
  headlessStyle: PropTypes.bool,
  arrowLeft: PropTypes.bool,
  highlighted: PropTypes.bool,
};

AccordionSection.defaultProps = {
  open: false,
  embedded: false,
  underlined: false,
  centered: false,
  plusMinusIcons: false,
};

export default AccordionSection;
