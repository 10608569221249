/**
 * @file
 *
 * Every reducer in our project necessarily has to be added to this file.
 */

import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import pages from './pages';
import entities from './entities';
import tracking from './tracking';
import user from './user';
import lifecycle from './lifecycle';
import orderProcess from './orderProcess';
import cart from './cart';
import menu from './menu';
import ui from './ui';
import legal from './legal';
import callout from './callout';
import order from './order';
import requestQueue from './requestQueue';
import requestQueueParallel from './requestQueueParallel';
import site from './site';
import search from './search';
import storage from './storage';
import friendReferral from './friendReferral';
import captcha from './captcha';

export default {
  routing: routerReducer,
  form: formReducer,
  pages,
  lifecycle,
  tracking,
  user,
  entities,
  orderProcess,
  cart,
  menu,
  ui,
  callout,
  legal,
  order,
  requestQueue,
  requestQueueParallel,
  site,
  search,
  storage,
  friendReferral,
  captcha,
};
