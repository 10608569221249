import { LOCATION_CHANGE, push } from 'react-router-redux';

import ApiError from '../model/errors/ApiError';
import SessionExpiredError from '../model/errors/SessionExpiredError';
import EntityRequest from '../model/requests/EntityRequest';
import { showAlert } from '../actions/page/dialog';
import { LOGOUT } from '../actions/user/logout';
import {
  REQUEST_RECEIVED_ERROR,
  REQUEST_RECEIVED_RESPONSE_META,
  REQUEST_RECEIVED_RESPONSE,
} from '../actions/request/basic';
import {
  unregisterAllRequests,
} from '../actions/request/sendQueued';
import { isAccountUrl } from '../config/api';
import { activateMaintenanceMode } from '../actions/compositions/session';
import { handleMeta } from '../actions/middleware/response';
import { BLOCK_VIEWPORT } from '../actions/site/viewport';
import { toggleDocumentScrolling } from '../helpers/navigation';
import { moduleConfigsToComponents } from '../helpers/pageParser';
import { send } from '../actions/request/send';
import LogoutRequest from '../model/requests/logoutRequest';
import LoginRequest from '../model/requests/LoginRequest';
import { fetchMyPromotions } from '../actions/request/registry';

const miscMiddleware = ({ getState, dispatch }) => next => async action => {
  switch (action.type) {

    case LOGOUT: {
      const { site, user } = getState();
      const { error } = action.payload;
      if (isAccountUrl(site.routing.requestedLocation)) {
        // redirect to home if logout success route doesn't exist
        const url = (site.sitemap.MyLogoutSuccessRoute || {}).url || '/';
        dispatch(push(url));
      }
      dispatch(unregisterAllRequests(new SessionExpiredError()));
      dispatch(send(new LogoutRequest(user.credentials.msisdn)));
      // we call next before we dispatch the alert so the site reducer
      // won't clear the logout message
      const dispatchResult = next(action);
      if (error) {
        dispatch(showAlert(error));
      }
      return dispatchResult;
    }

    case REQUEST_RECEIVED_RESPONSE_META: {
      await dispatch(handleMeta(action.payload.meta, action.meta.request));
      return next(action);
    }

    case REQUEST_RECEIVED_RESPONSE: {
      const { payload, meta } = action;
      const { request } = meta;
      const { body } = payload.response;
      const state = getState();

      if (request instanceof LoginRequest && state.site.appView) {
        const results = next(action);
        dispatch(fetchMyPromotions({ isBlocking: true }, true));
        return results;
      }

      /*
      * Parse EntityRequest's Response if it contains module configurations.
      * Config's are then mapped to React Components / Modules
      */
      if (!body.data.modules || !(request instanceof EntityRequest)) {
        return next(action);
      }

      const { site } = getState();
      const { routing } = site;

      const modules = await moduleConfigsToComponents(
        body.data.modules,
        routing.requestedLocation,
        dispatch,
      );

      action.payload.response.body.data = { // eslint-disable-line
        ...body.data,
        modules,
      };

      return next(action);
    }

    case REQUEST_RECEIVED_ERROR: {
      const { error } = action.payload;

      if (error instanceof ApiError) {
        if (error.fullResponse.status === 503 || error.request.isCritical()) {
          dispatch(activateMaintenanceMode(error));
        }
      }
      return next(action);
    }

    case BLOCK_VIEWPORT: {
      const { blocking, scrollToTop } = action.payload;
      if (blocking !== getState().site.isBlockingViewport) {
        toggleDocumentScrolling(blocking, scrollToTop);
      }
      return next(action);
    }

    case LOCATION_CHANGE: {
      const dispatchResult = next(action);
      // reset the focus after a page load
      // eslint-disable-next-line no-undef
      const body = document.querySelector('body');
      body.setAttribute('tabindex', '-1');
      body.focus();
      body.removeAttribute('tabindex');
      return dispatchResult;
    }

    default:
      return next(action);
  }
};

export default miscMiddleware;
