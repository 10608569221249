import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { closest } from '../../../helpers/dom';

import FAQAccordion from '../../basics/accordion/FAQAccordion';

class FAQ extends PureComponent {

  constructor(props, context) {
    super(props, context);
    const {
      open,
      hash,
      locationHash,
      expandedGroup,
    } = props;
    const selectedGroup = this.getSelectedGroup(locationHash);
    const selectedQuestion = this.getSelectedQuestion(locationHash);
    this.state = {
      isMounted: false,
      locationHash,
      selectedGroups:
        selectedGroup ? [selectedGroup] : expandedGroup.length > 0 ? expandedGroup : [],
      selectedQuestions: selectedQuestion ? [selectedQuestion] : [],
      open: open || !!selectedGroup || locationHash === hash,
    };
    this.onContentClick = this.onContentClick.bind(this);
    this.onToggleSection = this.onToggleSection.bind(this);
    this.onToggleGroup = this.onToggleGroup.bind(this);
    this.onToggleQuestion = this.onToggleQuestion.bind(this);
  }

  componentDidMount() {
    const { trackTopic, trackDetail } = this.props;
    const { locationHash } = this.state;
    const group = this.getSelectedGroup(locationHash);
    const question = this.getSelectedQuestion(locationHash);

    // only track the current state
    if (question && question.hash) {
      trackDetail(question.hash);
    } else if (group && group.hash) {
      trackTopic(group.hash);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      open,
      selectedGroups,
      selectedQuestions,
      isMounted,
    } = this.state;
    const { hash, locationHash } = nextProps;
    if ((!!locationHash && locationHash !== this.props.locationHash) || !isMounted) {
      const group = this.getSelectedGroup(locationHash);
      const question = this.getSelectedQuestion(locationHash);
      if (group && !selectedGroups.some(entry => entry === group)) {
        selectedGroups.push(group);
      }
      if (question && !selectedQuestions.some(entry => entry === question)) {
        selectedQuestions.push(question);
      }

      this.setState({
        isMounted: true,
        locationHash,
        selectedGroups,
        selectedQuestions,
        open: open || selectedGroups.length > 0 || locationHash === hash,
      });
    }

  }

  onContentClick(evt) {
    const { target } = evt;
    const targetSelector = 'a, button, [data-tracking-target]';
    const delegateTarget = closest(target, targetSelector, true);

    if (!delegateTarget) {
      return;
    }

    if (delegateTarget.hash) {
      this.props.onChange(delegateTarget.hash.slice(1));
    }
  }

  onToggleSection(open) {
    const { hash, trackTopic, onChange } = this.props;
    const { selectedGroups, selectedQuestions } = this.state;

    this.setState(
      {
        open,
        selectedGroups: open ? selectedGroups : [],
        selectedQuestions: open ? selectedQuestions : [],
      },
      () => {
        onChange(hash);
        if (open) {
          trackTopic(hash);
        }
      },
    );
  }

  onToggleGroup(group) {
    const { selectedGroups: groups, selectedQuestion } = this.state;
    const { hash, trackTopic, onChange } = this.props;
    const { hash: groupHash } = group;
    const selectedGroups = groups.slice(0);
    const groupIsOpen = selectedGroups.some(entry => entry === group);

    if (!groupIsOpen) {
      selectedGroups.push(group);
    } else {
      selectedGroups.splice(selectedGroups.indexOf(group), 1);
    }

    this.setState(
      { selectedGroups, selectedQuestions: groupIsOpen ? selectedQuestion : [] },
      () => {
        if (!groupIsOpen && groupHash) {
          onChange(groupHash);
          trackTopic(groupHash);
        } else {
          onChange(hash);
        }
      },
    );
  }

  onToggleQuestion({ open, question }) {
    const { onChange, trackDetail } = this.props;
    const { selectedQuestions: questions } = this.state;
    const selectedQuestions = questions.slice(0);
    const { hash } = question;

    if (open) {
      onChange(hash);
      trackDetail(hash);
    }

    if (!open && selectedQuestions.some(entry => entry === question)) {
      selectedQuestions.splice(selectedQuestions.indexOf(question), 1);
      onChange('');
      this.setState({ selectedQuestions });
    }
  }

  getSelectedGroup(hash) {
    const { groups } = this.props;
    return groups.find(group => group.hash === hash) ||
      groups.find(group => group.entries.some(entry => entry.hash === hash));
  }

  getSelectedQuestion(hash) {
    const selectedGroup = this.getSelectedGroup(hash);
    return selectedGroup && selectedGroup.entries.find(entry => entry.hash === hash);
  }

  render() {
    const {
      headline,
      groups,
      hash,
      headlineVisible,
      groupVisible,
      open,
      expandedGroup,
      forceOpen,
    } = this.props;
    return (
      <FAQAccordion
        groups={groups}
        groupVisible={groupVisible}
        headline={headline}
        headlineVisible={headlineVisible}
        hash={hash}
        isInitiallyOpen={open}
        forceOpen={forceOpen}
        expandedGroup={expandedGroup}
      />
    );
  }
}

FAQ.getGroupLabel = (group, index) => {
  let prefix = (index + 1).toString();
  if (prefix.length < 2) {
    prefix = `0${prefix}`;
  }
  return `<span>${prefix}.</span>${group.name}`;
};

FAQ.propTypes = {
  headline: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      hash: PropTypes.string,
      entries: PropTypes.arrayOf(
        PropTypes.shape({
          question: PropTypes.string.isRequired,
          answer: PropTypes.string.isRequired,
          hash: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }),
  ).isRequired,
  locationHash: PropTypes.string,
  hash: PropTypes.string,
  open: PropTypes.bool,
  forceOpen: PropTypes.bool,
  headlineVisible: PropTypes.bool.isRequired,
  groupVisible: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  trackTopic: PropTypes.func,
  trackDetail: PropTypes.func,
  expandedGroup: PropTypes.array,
};

export default FAQ;
