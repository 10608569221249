import React from 'react';
import PropTypes from 'prop-types';
import TextCopy from '../../components/basics/text/TextCopy';
import Link from '../../components/basics/text/TextLink';
import suitcss from '../../helpers/suitcss';

function CookieConsent(props) {
  const {
    copy,
    cookieSettings,
    wordGdprNotes,
    wordGdprNotesUrl,
    wordImprint,
    wordImprintUrl,
    showCookieConsentSettings,
  } = props;
  return (
    <div className={suitcss({}, this)}>
      <TextCopy raw className={suitcss({ descendantName: 'copy' }, this)}>{copy}</TextCopy>
      <div className={suitcss({ descendantName: 'links' }, this)}>
        <Link
          utilities={['sFlex', 'sCol12']}
          to={wordGdprNotesUrl}
          target="_blank"
          withoutArrow
        >
          {wordGdprNotes}
        </Link>

        <Link utilities={['sMarginTopS', 'mMarginLeftL', 'lMarginLeftXL', 'sFlex', 'sCol12']} withoutArrow element="button" onClick={showCookieConsentSettings}>{cookieSettings}</Link>

        <Link
          utilities={['sMarginTopS', 'mMarginLeftL', 'lMarginLeftXL', 'sFlex', 'sCol12']}
          to={wordImprintUrl}
          target="_blank"
          withoutArrow
        >
          {wordImprint}
        </Link>
      </div>
    </div>
  );
}

CookieConsent.propTypes = {
  showCookieConsentSettings: PropTypes.func.isRequired,
  copy: PropTypes.string,
  cookieSettings: PropTypes.string,
  wordGdprNotes: PropTypes.string,
  wordGdprNotesUrl: PropTypes.string,
  wordImprint: PropTypes.string,
  wordImprintUrl: PropTypes.string,
};

export default CookieConsent;
