/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import suitcss from '../../../helpers/suitcss';
import { MENU_IDENTIFIER_CART, MENU_IDENTIFIER_ACCOUNT } from '../../../helpers/constants';
import menuActions from '../../../actions/page/menu';
import { linkShape } from '../../../propTypes/typography';
import UIOverlayWrapper from '../../basics/ui/UIOverlayWrapper';
import CheckoutCartLink from './CheckoutCartLink';
import ShoppingCart from '../../../containers/cart/ShoppingCart';
import Link from '../../basics/text/TextLink';
import { isTariffEntity } from '../../../helpers/entity';
import { areObjectsEqual } from '../../../helpers/objectEquals';

class CheckoutCartNavigation extends PureComponent {

  constructor(...args) {
    super(...args);
    this.timeout = null;
    this.onOpenMenu = this.onOpenMenu.bind(this);
    this.onCloseMenu = this.onCloseMenu.bind(this);
  }

  componentWillUpdate(nextProps) {
    if (nextProps.shouldClose && this.timeout) {
      this.clearTimeout();
      this.props.closeMenu();
    }
  }

  componentWillUnmount() {
    this.clearTimeout();
  }

  onOpenMenu() {
    const { isOpen, shouldOpen, openMenu } = this.props;
    this.clearTimeout();
    if (!isOpen && shouldOpen) {
      openMenu();
    }
  }

  onCloseMenu() {
    const { closeMenu, isOpen } = this.props;
    this.clearTimeout();
    if (isOpen) {
      this.timeout = window.setTimeout(() => {
        closeMenu();
      }, 300);
    }
  }

  clearTimeout() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  renderContent() {
    return (<ShoppingCart theme="widget" isConfirmed />);
  }

  renderLink() {
    const {
      link,
      isOpen,
      cartBundleCount,
    } = this.props;

    return (
      <CheckoutCartLink
        to={link.url}
        icon={link.icon}
        isActive={isOpen}
        cartBundleCount={cartBundleCount}
        ariaLabel={link.ariaLabel}
      >
        {!link.icon && link.title}
      </CheckoutCartLink>
    );
  }

  render() {
    const {
     link, isOpen, isMediaSM, onSubmit,
    } = this.props;
    return (
      <div className={suitcss({}, this)}>
        <div
          className={suitcss({ descendantName: 'inner' }, this)}
        >
          {this.renderLink()}
          {!isMediaSM && (
            <UIOverlayWrapper
              theme="light"
              alignH="right"
              alignV="bottom"
              alignContent="outside"
              visible={isOpen}
            >
              {this.renderContent()}
              <Link
                className={suitcss({ descendantName: 'link' }, this)}
                element="button"
                asButton
                buttonFilled
                onClick={onSubmit}
                ariaLabel={link.ariaLabel}
              >
                {link.title}
              </Link>
            </UIOverlayWrapper>
          )}
        </div>
      </div>
    );
  }
}

CheckoutCartNavigation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  cartBundleCount: PropTypes.number,
  link: linkShape,
  isMediaS: PropTypes.bool,
  isMediaSM: PropTypes.bool,
  shouldOpen: PropTypes.bool,
  shouldClose: PropTypes.bool,
  location: PropTypes.object,
  ui: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

CheckoutCartNavigation.defaultProps = {
  isMediaS: false,
  isMediaSM: false,
};

// @todo otelo-nav-myotelo - use store items once available
const mapStateToProps = ({
 menu, cart, routing, site, ui,
}, ownProps) => {
  const menuConfig = menu[MENU_IDENTIFIER_CART] || {};
  const cartBundleCount = cart.filter(isTariffEntity).length;
  return {
    isOpen: menuConfig.isOpen || !!ownProps.isOpen,
    location: routing.locationBeforeTransitions,
    cartBundleCount,
    shouldClose: !ownProps.isMediaS
      && menu[MENU_IDENTIFIER_ACCOUNT] && menu[MENU_IDENTIFIER_ACCOUNT].isOpen,
    shouldOpen: cartBundleCount > 0 && site.sitemap
      && routing.locationBeforeTransitions.pathname !== site.sitemap.ShoppingCartRoute.url,
    ui,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openMenu: (blockScrolling) =>
    dispatch(menuActions.openMenu(MENU_IDENTIFIER_CART, blockScrolling)),
  closeMenu: () =>
    dispatch(menuActions.closeMenu(MENU_IDENTIFIER_CART)),
  onSubmit: async () => {
    const { url } = ownProps.link;
    dispatch(push(url));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    areStatesEqual: (next, prev) => areObjectsEqual(next, prev, {
      menu: {},
      cart: {},
      routing: {},
      site: {},
      ui: {},
    }, false),
  },
)(CheckoutCartNavigation);
