import React, { useContext } from 'react';
import suitcss from '../../../helpers/suitcss';
import { NavigationContext } from './NavigationMain';

const SkipLinkNavigation = () => {
  const context = useContext(NavigationContext);

  /**
   * @param id
   */
  const focusElementById = (id) => {
    if (typeof document === 'undefined') {
      return;
    }
    // eslint-disable-next-line no-undef
    const element = document.getElementById(id);
    if (element) {
      element.focus();
      element.scrollIntoView();
    }
  };

  return (
    <nav
      className={suitcss({
        componentName: 'SkipLink',
      }, this)}
    >
      <a
        href="#MainNavigation"
        onClick={(event) => {
          event.preventDefault();
          if (context.ref) context.ref.toggleOpen();
          return false;
        }}
        className={suitcss({
          componentName: 'SkipLink',
          descendantName: 'link',
          modifiers: ['menuToggle'],
        }, this)}
        type="button"
      >
        Zur Hauptnavigation
      </a>
      <a
        href="#Content"
        onClick={(event) => {
          event.preventDefault();
          focusElementById('SkipLinkContentAnchor');
          return false;
        }}
        className={suitcss({
          componentName: 'SkipLink',
          descendantName: 'link',
        }, this)}
        type="button"
      >
        Zum Inhalt Springen
      </a>
      <a
        href="#Footer"
        onClick={(event) => {
          event.preventDefault();
          focusElementById('SkipLinkFooterAnchor');
          return false;
        }}
        className={suitcss({
          componentName: 'SkipLink',
          descendantName: 'link',
        }, this)}
        type="button"
      >
        Zum Footer springen
      </a>
    </nav>
  );
};

SkipLinkNavigation.defaultProps = {
};

export default SkipLinkNavigation;
