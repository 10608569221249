import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import FAQAccordionGroup from './FAQAccordionGroup';

const FAQAccordion = ({
  headline,
  headlineVisible,
  groups,
  groupVisible,
  hash,
  isInitiallyOpen,
  forceOpen,
  expandedGroup,
}) => {
  // when forceOpen is set, this is no interactive element anymore
  // html details/summary can - by design - not be disabled.
  // so when forceOpen is set, details/summary is not the correct
  // semantic element. So we use a generic div container
  const ElementFAQ = (forceOpen) ? 'div' : 'details';
  const ElementFAQTitle = (forceOpen) ? 'div' : 'summary';
  const summaryProps = (forceOpen) ? {} : {
    open: isInitiallyOpen,
  };

  return (
    <div
      className={suitcss({
        componentName: 'FAQ',
      }, this)}
    >
      <ElementFAQ
        id={hash}
        {...summaryProps}
        className={suitcss({
          componentName: 'FAQ',
          descendantName: 'main',
          modifiers: [forceOpen ? 'forceOpen' : ''],
        })}
      >
        <ElementFAQTitle
          className={suitcss({
            componentName: 'FAQ',
            descendantName: 'title',
          })}
        >
          {headlineVisible ? headline : ''}
        </ElementFAQTitle>
        <div
          className={suitcss({
            componentName: 'FAQ',
            descendantName: 'body',
          })}
        >
          {groups.map((group, groupIndex) => (
            <div
              className={suitcss({
                componentName: 'FAQ',
                descendantName: 'group',
              })}
            >
              <FAQAccordionGroup
                key={group.hash}
                group={group}
                groupIndex={groupIndex}
                groupVisible={groupVisible}
                isInitiallyOpen={expandedGroup.some(exGroup => exGroup?.hash === group.hash)}
              />
            </div>
          ))}
        </div>
      </ElementFAQ>
    </div>
  );
};

FAQAccordion.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      hash: PropTypes.string,
      entries: PropTypes.arrayOf(
        PropTypes.shape({
          question: PropTypes.string.isRequired,
          answer: PropTypes.string.isRequired,
          hash: PropTypes.string.isRequired,
        }).isRequired,
      ),
    }),
  ).isRequired,
  hash: PropTypes.string,
  headline: PropTypes.string,
  headlineVisible: PropTypes.bool,
  groupVisible: PropTypes.bool.isRequired,
  expandedGroup: PropTypes.array,
  isInitiallyOpen: PropTypes.bool,
  forceOpen: PropTypes.bool,
};

FAQAccordion.defaultProps = {
  headlineVisible: true,
  isInitiallyOpen: false,
  forceOpen: false,
};

export default FAQAccordion;
