import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowEqual from 'react-pure-render/shallowEqual';
import { connect } from 'react-redux';

import suitcss from '../../helpers/suitcss';
import UIProvider from '../../components/basics/ui/UIProvider';
import GlobalModule from './GlobalModule';
import { createCurrentPageSelector } from '../../selectors/page';
import PageContext from '../../components/basics/context/PageContext';
import SearchNavigation from '../../components/compositions/search/SearchNavigation';


class GlobalContent extends Component {

  shouldComponentUpdate(nextProps) {
    // In some strange situations, like call push [OT-1826] or using history back
    // functionality [OT-902], the page assumes it is ready to load, but the next page
    // is not defined yet and so it will throw an error.
    // This is due to the loading order in the middleware loader: The loader executes
    // the action and will than eventually handle the load of the page, which triggers all
    // other states. When updates went fast, this component will be updated before the loading
    // state was toggled.
    // @todo we may provide a higher order component to get the current page to prevent from
    //       this issue in various situations. GlobalMetadata is also requesting the page
    //       and is running into the same issue.
    if (!nextProps.page) {
      return false;
    }
    // if the next page is not falsy we still just want the component to update as needed
    return !shallowEqual(nextProps, this.props);
  }

  /**
   * @param {PageModule} module
   * @param index
   * @return {Component}
   */
  renderModule(module, index, modules) {
    const { primaryModule } = this.props;
    const isPrimaryModule = primaryModule
      ? primaryModule.eid === module.eid
      : module.isPrimaryModule(modules, index);
    return (
      <UIProvider
        ui={module.ui}
        key={`${module.eid}${index}`}
      >
        <GlobalModule
          module={module}
          primaryModule={isPrimaryModule}
          location={this.props.locationBeforeTransitions}
        />
      </UIProvider>
    );
  }

  render() {
    const { page, isLazyShowPage } = this.props;
    if (!page) { return null; }
    // TODO Remove the static query selector
    return (
      <PageContext.Provider value={{ isLazyShowPage }}>
        <div id="SkipLinkContentAnchor" className={suitcss({}, this)} tabIndex="-1">
          {page.modules.map(this.renderModule, this)}
          <SearchNavigation classForHtmlBind={'.NavigationMain-link.NavigationMain-link--navMainSearch'} />
        </div>
      </PageContext.Provider>
    );
  }
}

GlobalContent.propTypes = {
  // @todo page should have its own proptype that is defined in an other file
  page: PropTypes.object,
  locationBeforeTransitions: PropTypes.object.isRequired,
  isLazyShowPage: PropTypes.bool.isRequired,
};

const makeMapStateToProps = () => {
  const currentPageSelector = createCurrentPageSelector();
  return (state, ownProps) => {
    const { routing, pages } = state;
    const location = routing.locationBeforeTransitions;
    const pageConfig = (location && pages[location.pathname] && pages[location.pathname].config);
    const behaviorSettings = !!pageConfig && pageConfig.behaviorSettings;
    const page = currentPageSelector(state, ownProps);
    return {
      page,
      primaryModule: page.primaryModule
        ? page.modules.find(module => module.eid === page.primaryModule)
        : null,
      locationBeforeTransitions: location,
      isLazyShowPage: !!behaviorSettings && behaviorSettings.lazyShow,
    };
  };
};

export default connect(
  makeMapStateToProps,
)(GlobalContent);
