import { removeFromHistory } from './basic';
import { sendQueued } from './sendQueued';
import { sendDirect } from './sendDirect';
import { devWarning } from '../../helpers/meta';
import { REQUEST_METHOD_GET } from '../../helpers/constants';
import { sendParallel } from './sendParallel';

/**
 * Sends the request and chooses an appropriate sending mechanism.
 * For secured account urls the sequential sending queue is used,
 * in any other case, the standard async send action is used.
 *
 * @param {QueueableRequest} request
 * @return {ApiResponse}
 */
export const send = request => async (dispatch, getState) => {
  const sendAction = request.isQueued() ? (
    request.isParallelized() ? sendParallel : sendQueued
  ) : sendDirect;
  const state = getState();
  if (request.block(state)) {
    return null;
  }

  const response = await dispatch(sendAction(request));

  if (request.method !== REQUEST_METHOD_GET) {
    try {
      request.getSubsequentRequests(state, response).forEach(subseqRequest => {
        subseqRequest.setBlocking(true); // always force blocking for subsequent requests!
        dispatch(removeFromHistory(subseqRequest));
        dispatch(send(subseqRequest));
      });
    } catch (e) {
      devWarning('Failed to execute refresh list for', request);
    }
  }

  return response;
};

/**
 * This function is useful if you want to populate the store by sending out only
 * those requests that have not been send yet. It is expected that once a request
 * arrives, the redux-reducers will kick in and update the store.
 *
 * @todo we can give each request a timestamp and check for its age and whether it's too old
 *
 * @param {QueueableRequest} request
 * @param {boolean} [isForce=false] - fetch from server even if the exact request has
 *     already been send before. note: isForce only affects requests with safe methods,
 *     which means that non-retrieving requests are always forced.
 * @return {Promise<boolean, Error>} This function always resolves true if the request
 *   was send, false otherwise. If the promise is rejected, an error is returned.
 */
export const sendIfNecessary = (request, isForce = false) => async (dispatch, getState) => {
  const { requestHistory } = getState().site;
  if (isForce || !requestHistory.includes(request.id)) {
    await dispatch(send(request));
    return true;
  }

  return false;
};
