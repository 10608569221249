import React from 'react';
import RouterLink from 'react-router/lib/Link';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { getBrowserInfo, openUrl } from '../../../services/bridge';
import SvgLoader from '../media/MediaSvgLoader';

export const getTargetType = (to, target) => {
  // we do not like to access the window.location so we just guess that its
  // an external link
  const isExternal = to && (
    to.startsWith('http://') ||
    to.startsWith('https://') ||
    to.startsWith('//') ||
    to.startsWith('tel:') ||
    target === '_blank'
  );
  // if target is set, react router will not redirect the page but will perform
  // a full page reload. In case its a relative link and target was set to '_self'
  // we disable the target because react-router can handle best.
  let newTarget = target;
  if (isExternal && !target) {
    newTarget = '_self';
  }
  if (!isExternal && target === '_self') {
    newTarget = null;
  }

  // always override target for pdf
  if (to && /\.pdf$/.test(to.toLowerCase())) {
    newTarget = '_blank';
  }
  return newTarget;
};

const componentName = 'TextLink';

function TextLink(props) {
  const {
    element,
    isActive,
    isDisabled,
    className,
    utilities,
    data,
    href,
    isLoading,
    ariaLabel,
    linkId,
  } = props;
  // if disbled and an "a" tag it is turned into a span because it cannot be disabled otherwise
  const Element = isDisabled && (!element || element === 'a') ? 'span' : (element || RouterLink);
  const target = getTargetType(props.to, props.target);
  const states = [
    isActive && 'active',
    isDisabled && 'disabled',
    isLoading && 'loading',
  ];

  // @todo describe what happens here and how this is related to tracking.
  const dataset = Object.keys(data || {}).reduce((result, key) => {
    result[`data-${key}`] = data[key]; // eslint-disable-line
    return result;
  }, {});

  if (element === 'a' && process.browser && process.env.NODE_ENV !== 'production') {
    console.warn('Prop "element" should not be "a". For "a" tags ommit Prop "element"');
  }

  let onClick = null;
  let to = typeof Element === 'string' && Element !== 'a' ? null : props.to;
  if (!isDisabled) {
    if (props.onClick) {
      onClick = props.onClick;
    } else if (to && target === '_blank' && getBrowserInfo().isWebView) {
      onClick = ev => { ev.preventDefault(); openUrl(props.to); };
      to = null;
    }
  } else {
    to = undefined;
    onClick = (ev) => { ev.preventDefault(); return false; };
  }

  const modifiers = [
    props.size,
    props.color,
    props.outline,
    props.icon && 'icon',
    props.buttonFilled && 'buttonFilled',
    !props.withoutArrow && !props.withoutStyle && 'arrow',
    props.buttonWithoutLabel && 'buttonWithoutLabel',
    props.tertiary && 'tertiary',
    props.highlight && 'highlight',
    props.asShareButton && 'asShareButton',
    props.asShareButtonInverted && 'asShareButtonInverted',
    props.withoutStyle && 'noStyle',
    // if 'asButton' is set it should always render as button
    props.asButton === true && 'asButton',
    // if 'asButton' is set to a breakpoint (e.g. asButton="SM")
    // it should only render as button on set breakpoint
    typeof props.asButton === 'string' && `asButton-${props.asButton}`,
    props.noHover && 'noHover',
  ];
  return (
    <Element
      id={linkId}
      to={href ? null : to}
      href={href}
      onClick={onClick}
      className={suitcss({
        componentName,
        modifiers,
        states,
        className,
        utilities,
      })}
      aria-label={ariaLabel}
      target={target}
      {...dataset}
    >
      {props.icon && (
        <span className={suitcss({ componentName, descendantName: 'icon' })} >
          <SvgLoader path={props.icon} />
        </span>
      )}

      {props.children && (
        <span className={suitcss({ componentName, descendantName: 'text' })} >
          {props.children}
        </span>
      )}

      {props.isLoading && (
        <span className={suitcss({ componentName, descendantName: 'loadingIcon' })} >
          <SvgLoader path="/icons/content-spinner.svg" />
        </span>
      )}
    </Element>
  );
}

TextLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  data: PropTypes.object, // @todo prop name too generic. rename into trackingData
  size: PropTypes.oneOf([
    'secondary',
    'small',
  ]),
  element: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  color: PropTypes.oneOf([
    'dark',
    'light',
  ]),
  asButton: PropTypes.oneOfType([
    PropTypes.oneOf([
      'S',
      'SM',
      'M',
      'ML',
      'L',
    ]),
    PropTypes.bool,
  ]),
  withArrow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  withoutLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  utilities: PropTypes.array,
  target: PropTypes.oneOf(['_blank', '_self']),
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  buttonFilled: PropTypes.bool,
  tertiary: PropTypes.bool,
  highlight: PropTypes.bool,
  withoutArrow: PropTypes.bool,
  withoutStyle: PropTypes.bool,
  buttonWithoutLabel: PropTypes.bool,
  href: PropTypes.string,
  asShareButton: PropTypes.bool,
  asShareButtonInverted: PropTypes.bool,
  isLoading: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

TextLink.defaultProps = {
  to: '/',
  data: {},
};

export default TextLink;
