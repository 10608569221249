import React from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import SvgLoader from '../../basics/media/MediaSvgLoader';
import { capitalize } from '../../../helpers/str';

function ContentTabItem(props) {
  const { activeTab, label, identifier, colorScheme, onClick, showCheck } = props;
  const isActive = activeTab === label;
  return (
    <button
      className={suitcss({
        componentName: 'ContentTabs',
        modifiers: [
          isActive && 'active',
          colorScheme && `theme${capitalize(colorScheme)}`,
          showCheck && 'withCheck',
        ],
        descendantName: 'navigation',
      })}
      onClick={() => onClick(identifier)}
      key={identifier}
    >
      {showCheck && <SvgLoader path={`/icons/icon-${isActive ? 'active' : 'inactive'}.svg`} inverted={colorScheme === 'dark'} />}
      {label}
    </button>);
}

ContentTabItem.propTypes = {
  activeTab: PropTypes.string,
  identifier: PropTypes.number,
  label: PropTypes.string,
  onClick: PropTypes.func,
  colorScheme: PropTypes.oneOf(['dark', 'none', 'light']),
  showCheck: PropTypes.bool,
};

export default ContentTabItem;
