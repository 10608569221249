/* global process */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { shape as calloutShape } from '../../propTypes/callout';
import CalloutComponent from '../../components/basics/callout/Callout';
import { createCalloutSelector } from '../../selectors/callout';
import { getRemainingTimeFormated } from '../../helpers/date';
import { showSimpleDialog } from '../../actions/dialog/misc';

const TYPE_TEXT = 'text';
const TYPE_COUNTER = 'counter';
const TYPE_TIMER = 'timer';

class Callout extends Component {

  constructor(props) {
    super(props);
    this.state = { time: Date.now() };
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    this.mountTimer();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  /**
   * Returns the value of the counter that represents the amount of a given number of pieces.
   * It is calculated via a start time and an end time and the starting number of the counter.
   * In this case the function is an exponential function to mimic a natural decrease of the
   * amount. It is moved down the y-axis to pass the x-axis at the end time. This is done by
   * the linear part. The smoothness can be changed to configure the amount of linearity,
   * where smaller than 1 is more linear and bigger becomes more cubic.
   *
   * @returns {*} A rounded number
   */
  getCounterValue() {
    const { callout } = this.props;
    const { time } = this.state;
    const { startDate, endDate, counterStart } = callout;
    const endTimeMillis = new Date(endDate).getTime();
    const startTimeMillis = new Date(startDate).getTime();
    const timeSpan = endTimeMillis - startTimeMillis;
    if (timeSpan === 0) {
      return 0;
    }
    const smoothness = 5;
    const slope = -(counterStart / timeSpan);
    const t = time - startTimeMillis;
    const linearPart = slope * Math.exp(-smoothness) * (t);
    const exponentialPart = counterStart * Math.exp(-(smoothness * t) / timeSpan);
    return Math.round(exponentialPart + linearPart);
  }

  /**
   * Returns the value of the callout as a function of the given type.
   */
  getValueByType() {
    const { callout } = this.props;
    const { time } = this.state;
    const { endDate, type } = callout;

    switch (type) {
      case TYPE_COUNTER: {
        return this.getCounterValue();
      }
      case TYPE_TIMER: {
        return getRemainingTimeFormated(new Date(endDate).getTime() - time);
      }
      case TYPE_TEXT:
        return '';
      default:
        return '';
    }
  }

  /**
   * Updates the component
   */
  update() {
    this.setState({ time: Date.now() });
  }

  /**
   * Mounts the timer if the component is time dependent
   */
  mountTimer() {
    const { callout } = this.props;
    if (callout) {
      const { type } = callout;
      if (type === TYPE_TIMER || type === TYPE_COUNTER) {
        this.timer = setInterval(this.update, 1000);
      }
    }
  }

  /**
   * Handles conditinal rendering of the callout.
   * @returns {boolean}
   */
  shouldRender() {
    const { callout } = this.props;

    if (!callout || (Array.isArray(callout) && !callout.length)) {
      return null;
    }

    const { time } = this.state;
    const { startDate, endDate, type } = callout;

    if (type !== TYPE_COUNTER && type !== TYPE_TIMER) {
      return true;
    }

    const hasStarted = new Date(startDate).getTime() - time < 0;
    const hasEnded = (endDate && new Date(endDate).getTime() - time < 0)
      || (type === TYPE_COUNTER && this.getCounterValue() <= 0);

    return hasStarted && !hasEnded;
  }

  renderMultiple() {
    const { callout, theme, colorScheme, utilities } = this.props;
    const value = this.getValueByType();
    const getCalloutContent = (c) => (c.textInfo ? `<span>${c.copy.replace('{VALUE}', value)} <button class="with-info-icon"></button></span>` : c.copy.replace('{VALUE}', value));
    return (
      callout.map((c, index) => (
        <CalloutComponent
          {...this.props}
          callout={c}
          content={getCalloutContent(c)}
          subcontent={c.subcontent}
          theme={theme || c.theme}
          color={colorScheme || c.colorScheme}
          link={c.link}
          utilities={utilities}
          key={index}
        />
      ))
    );
  }

  render() {
    if (!this.shouldRender()) {
      return null;
    }
    if (this.props.allowMultiple) {
      return this.renderMultiple();
    }
    const { callout, theme, colorScheme, utilities } = this.props;
    const { copy, secondaryCopy: subcontent, link, textInfo } = callout;
    const value = this.getValueByType();
    const content = copy.replace('{VALUE}', value);
    const calloutContent = textInfo ? `<span>${content} <button class="with-info-icon"></button></span>` : content;

    return (
      <CalloutComponent
        {...this.props}
        content={calloutContent}
        subcontent={subcontent}
        theme={theme || callout.theme}
        color={colorScheme || callout.colorScheme}
        link={link}
        utilities={utilities}
      />
    );
  }
}

Callout.propTypes = {
  callout: PropTypes.oneOfType([calloutShape, PropTypes.arrayOf(calloutShape)]),
  theme: PropTypes.oneOf(['badge', 'label', 'content', 'sticker']),
  colorScheme: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  align: PropTypes.string,
  inverted: PropTypes.bool,
  bordered: PropTypes.bool,
  expanded: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  targets: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        eid: PropTypes.string.isRequired,
      }),
    ]),
  ),
  utilities: PropTypes.arrayOf(PropTypes.string),
};

const makeMapStateToProps = () => {
  const calloutSelector = createCalloutSelector();
  return (state, ownProps) => ({
    callout: ownProps.callout || calloutSelector(state, ownProps),
  });
};

const mapDispatchToProps = (dispatch) => ({
  onShowSimpleDialog: (headline, copy, label) => {
    dispatch(showSimpleDialog(headline, copy, label));
  },
});

export default connect(makeMapStateToProps, mapDispatchToProps)(Callout);

// eslint-disable-next-line no-unused-expressions
process.env.NODE_ENV === 'test' && Object.assign(module.exports, {
  makeMapStateToProps,
});
