'use client';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import RouterLink from 'react-router/lib/Link';
import SvgLoader from '../../basics/media/MediaSvgLoader';

import suitcss from '../../../helpers/suitcss';
import connectUI from '../../basics/ui/UIConnector';
import { formatDate } from '../../../helpers/date';
import TextPlain from '../../basics/text/TextPlain';
import { htmlToText } from '../../../helpers/dom';

const componentName = 'DownloadListItem';
class DownloadListItem extends PureComponent {

  getFileMetaString = (prefix, size) => {
    const { ui } = this.props;
    const sizeExponents = ['Bytes', 'Kilobyte', 'Megabyte', 'Gigabyte', 'Terabyte', 'Petabyte'];

    const getFormatedSize = () => {
      if (!size) return '0'; // Handle edge case for undefined size

      const exp = Math.floor(Math.log(size) / Math.log(1024));
      const postfix = ui[`guiSymbol${sizeExponents[exp]}`] || ''; // Fallback for missing symbol
      const sizeStr = (size / (1024 ** exp)).toFixed(1).replace('.', ui.guiSymbolComma);

      return `${sizeStr} ${postfix}`.trim();
    };

    if (!size) return prefix || getFormatedSize(); // Handle both cases together
    return [prefix.toUpperCase(), getFormatedSize()].filter(Boolean).join(' ');
  };

  render() {
    const {
      date,
      copy,
      file,
    } = this.props;
    const formattedDate = formatDate(date, 'short');
    const iconPath = '/icons/content-download.svg';

    const plainTitle = htmlToText(copy);
    const fileString = this.getFileMetaString(file.extension, file.size);
    const ariaLabelString = `${plainTitle}${formattedDate !== 'Invalid Date' ? ` vom ${formattedDate}` : ''}${fileString ? `, ${fileString}` : ''}`;

    return (
      <li className={suitcss({ componentName })}>
        <RouterLink
          className={suitcss({
            componentName,
            descendantName: 'linkBox',
          })}
          to={file.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sr-only">{ariaLabelString}</span>
          <div
            className={suitcss({
              componentName,
              descendantName: 'textCol',
            })}
            aria-hidden
          >
            {date && (<time dateTime={formattedDate}>{formattedDate}</time>)}
            <TextPlain element="p">
              {copy}
            </TextPlain>
          </div>
          <div
            className={suitcss({
              componentName,
              descendantName: 'iconCol',
            })}
            aria-hidden
          >
            <SvgLoader path={iconPath} />
            {file && (
              <p>{fileString}</p>
            )}
          </div>
        </RouterLink>
      </li>
    );
  }
}

DownloadListItem.propTypes = {
  date: PropTypes.string,
  copy: PropTypes.string.isRequired,
  file: PropTypes.object,
  ui: PropTypes.shape({
    guiSymbolComma: PropTypes.string.isRequired,
  }),
};

export default connectUI()(DownloadListItem);
