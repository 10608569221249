import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import { extractDataAttributes } from '../../../helpers/misc';
import { ariaHtmlAttributes, ariaPropsTypes } from '../../../helpers/ariaAttributes';

function FormOptionBullet(props) {
  const inputProps = {
    id: props.id,
    name: props.name,
    value: props.value,
    checked: props.checked,
  };

  const states = [
    props.checked && 'selected',
    props.disabled && ' disabled',
  ];

  const modifiers = [
    props.filled && 'filled',
  ];

  const buttonRef = useRef(null);
  const handleClick = () => {
    const data = {
      target: {
        id: props.id,
        name: props.name,
        value: props.value,
        checked: !props.checked,
      },
    };

    props.onChange(data);

    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  const dataset = extractDataAttributes(props.data);

  return (
    <button
      ref={buttonRef}
      className={suitcss({
        componentName: 'FormOptionBullet',
        states,
        modifiers,
      })}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={handleClick}
      {...dataset}
    >
      <input
        className={suitcss({
          componentName: 'FormOptionBullet',
          descendantName: 'input',
        })}
        type="radio"
        {...inputProps}
        onChange={props.onChange}
        data-tracking="off"
        aria-label={props.label}
        tabIndex="-1"
        {...ariaHtmlAttributes(props)}
      />

      <label
        className={suitcss({
          componentName: 'FormOptionBullet',
          descendantName: 'label',
        })}
        htmlFor={props.id}
      >
        <span
          className={suitcss({
            componentName: 'FormOptionBullet',
            descendantName: 'labelInner',
          })}
          style={{ backgroundColor: props.value }}
        />
      </label>
    </button>
  );
}

FormOptionBullet.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  filled: PropTypes.bool,
  data: PropTypes.object,
  ...ariaPropsTypes,
};

FormOptionBullet.defaultProps = {
  checked: false,
  disabled: false,
  filled: false,
};

export default FormOptionBullet;
