import React from 'react';
import PropTypes from 'prop-types';
import Copy from '../text/TextCopy';
import suitcss from '../../../helpers/suitcss';

const FAQAccordionGroup = ({ group, groupIndex, groupVisible, isInitiallyOpen }) => {
  const label = `${String(groupIndex, '').padStart(2, '0')}. ${group.name}`;
  if (groupVisible) {
    return (
      <details open={isInitiallyOpen}>
        <summary
          className={suitcss({
            componentName: 'FAQ',
            descendantName: 'subtitle',
          })}
        >
          {label}
        </summary>
        <FAQAccordionGroup groupVisible={false} group={group} />
      </details>
    );
  } else {
    return (
      <div
        className={suitcss({
          componentName: 'FAQ',
          descendantName: 'groupBody',
        })}
      >
        {group.entries.map((entry) => (
          <details
            key={entry.hash}
            className={suitcss({
              componentName: 'FAQ',
              descendantName: 'entry',
            })}
          >
            <summary
              className={suitcss({
                componentName: 'FAQ',
                descendantName: 'question',
              })}
            >
              {entry.question}
            </summary>
            <div
              className={suitcss({
                componentName: 'FAQ',
                descendantName: 'answer',
              })}
            >
              <Copy embedded raw>
                {entry.answer}
              </Copy>
            </div>
          </details>
        ))}
      </div>
    );
  }
};

FAQAccordionGroup.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string.isRequired,
    hash: PropTypes.string,
    entries: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string.isRequired,
        answer: PropTypes.string.isRequired,
        hash: PropTypes.string.isRequired,
      }).isRequired,
    ),
  }).isRequired,
  groupIndex: PropTypes.number,
  groupVisible: PropTypes.bool.isRequired,
  isInitiallyOpen: PropTypes.bool.isRequired,
};



export default FAQAccordionGroup;
