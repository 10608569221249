/* global document */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';

import ReactFocusLock from 'react-focus-lock';
import { capitalize } from '../../../helpers/str';
import matchMediaConnector from '../../../containers/service/ServiceMatchMedia';
import suitcss from '../../../helpers/suitcss';
import ButtonCloseIcon from '../button/ButtonCloseIcon';

const ESC_KEYCODE = 27;
const PARENT_DURATION = 300;
const CHILD_DURATION = 275;

/**
 * SEO: added width and height 100% to PoseLightbox visible
 * @see https://jira.db-n.com/browse/OP-2429
 */

const PoseLightbox = posed.div({
  visible: {
    opacity: 1,
    applyAtStart: { display: 'block', width: '100%', height: '100%' },
    delay: ({ delayEnter }) => delayEnter,
    transition: {
      duration: PARENT_DURATION,
      ease: 'anticipate',
    },
  },
  hidden: {
    opacity: 0,
    applyAtEnd: { display: 'none' },
    delay: ({ delayExit }) => delayExit,
    transition: {
      duration: PARENT_DURATION * 0.8,
      ease: 'circIn',
    },
  },
});

class Lightbox extends PureComponent {

  static getDerivedStateFromProps(props, state) {
    const { eyecandy } = props;
    const { Particles } = state;
    return !eyecandy && Particles ? { Particles: null } : null;
  }

  constructor(props, context) {
    super(props, context);
    this.state = {
      Particles: null,
    };
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onPoseParentComplete = this.onPoseParentComplete.bind(this);
    this.onPoseChildComplete = this.onPoseChildComplete.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false);
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    // On open get the current focused element
    if (isVisible && !prevProps.isVisible) {
      this.setState({ lastFocusedElement: document.activeElement });
    }

    // On close put focus back on old element
    if (!isVisible && prevProps.isVisible) {
      const { lastFocusedElement } = this.state;
      if (lastFocusedElement && typeof lastFocusedElement.focus === 'function') {
        lastFocusedElement.focus();
      }
    }
  }

  onKeyDown(ev) {
    if (ev.keyCode === ESC_KEYCODE) {
      this.onClose();
    }
  }

  onClose() {
    const { isVisible, onClose } = this.props;
    if (isVisible && onClose) {
      onClose();
    }
  }

  onPoseChildComplete(pose) {
    const { onHideChild, onShowChild } = this.props;
    if (pose === 'exit' && onHideChild) {
      onHideChild();
    } else if (pose === 'enter' && onShowChild) {
      onShowChild();
    }
  }

  onPoseParentComplete(pose) {
    const { onHide, onShow } = this.props;
    if (pose === 'hidden' && onHide) {
      onHide();
    } else if (pose === 'visible') {
      this.loadParticlesJs();
      if (onShow) {
        onShow();
      }
    }
  }

  loadParticlesJs() {
    const { Particles } = this.state;
    if (!Particles) {
      require.ensure([], require => {
        this.setState({ Particles: require('react-particles-js').default });
      }, 'react-particles-js');
    }
  }

  render() {
    const {
      className,
      eyecandy,
      alignH,
      alignV,
      children,
      onClose,
      isVisible,
      isMediaS,
      withContainer,
      withScrolling,
      onShowDelay,
      onHideDelay,
      onShowChildDelay,
      onHideChildDelay,
      withoutLightboxFadeout,
    } = this.props;

    const { Particles } = this.state;

    if (children && !children.key) {
      throw (new Error('Lightbox children require a unique "key" prop'));
    }
    this.PoseContent = posed.div({
      enter: {
        y: 0,
        opacity: 1,
        delay: ({ delayEnter }) => delayEnter,
        transition: {
          duration: withoutLightboxFadeout ? 2 : CHILD_DURATION,
          ease: 'easeOut',
        },
      },
      exit: {
        y: 12,
        opacity: 0,
        delay: ({ delayExit }) => delayExit,
        transition: {
          duration: withoutLightboxFadeout ? 2 : CHILD_DURATION,
          ease: 'backIn',
        },
      },
    });

    return (
      <ReactFocusLock disabled={!isVisible}>
        <PoseLightbox
          className={suitcss({
            modifiers: [
              withContainer && 'contained',
              withScrolling && 'scrollable',
              alignH && `align${capitalize(alignH)}`,
              alignV && `align${capitalize(alignV)}`,
            ],
            className,
          }, this)}
          pose={isVisible ? 'visible' : 'hidden'}
          onPoseComplete={this.onPoseParentComplete}
          delayEnter={onShowDelay}
          delayExit={onHideDelay}
          withParent={false}
        >
          <div className={suitcss({ descendantName: 'backdrop' }, this)}>
            {!isMediaS && eyecandy && !!Particles && (
              <Particles params={eyecandy} />
            )}
          </div>
          <div
            className={suitcss({ descendantName: 'inner' }, this)}
            onClick={this.onClose}
          >
            <PoseGroup flipMove={false}>
              {isVisible && children && (
                <this.PoseContent
                  className={suitcss({ descendantName: 'content' }, this)}
                  onClick={ev => ev.stopPropagation()}
                  onPoseComplete={this.onPoseChildComplete}
                  delayEnter={onShowChildDelay}
                  delayExit={onHideChildDelay}
                  key={children.key}
                >
                  {withContainer && onClose &&
                    <ButtonCloseIcon
                      className={suitcss({ descendantName: 'close' }, this)}
                      onClick={this.onClose}
                    />
                  }
                  {children}
                </this.PoseContent>
              )}
            </PoseGroup>
          </div>
        </PoseLightbox>
      </ReactFocusLock>
    );
  }
}

Lightbox.propTypes = {
  alignH: PropTypes.oneOf(['left', 'right', 'center']),
  alignV: PropTypes.oneOf(['top', 'bottom', 'middle']),
  className: PropTypes.string,
  children: PropTypes.shape({
    key: PropTypes.string.isRequired,
  }),
  isVisible: PropTypes.bool,
  eyecandy: PropTypes.object,
  onClose: PropTypes.func,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  onShowChild: PropTypes.func,
  onHideChild: PropTypes.func,
  onShowDelay: PropTypes.number,
  onShowChildDelay: PropTypes.number,
  onHideDelay: PropTypes.number,
  withoutLightboxFadeout: PropTypes.bool,
  onHideChildDelay: PropTypes.number,
  withContainer: PropTypes.bool,
  withScrolling: PropTypes.bool,
  isMediaS: PropTypes.bool,
};

Lightbox.defaultProps = {
  alignH: 'center',
  alignV: 'middle',
  withScrolling: true,
  onShowDelay: 0,
  onShowChildDelay: PARENT_DURATION * 0.7,
  onHideDelay: CHILD_DURATION,
  onHideChildDelay: 0,
};

export default matchMediaConnector(['isMediaS'])(Lightbox);
