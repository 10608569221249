import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import suitcss from '../../../helpers/suitcss';
import SvgLoader from '../media/MediaSvgLoader';
import { extractDataAttributes } from '../../../helpers/misc';
import { ariaHtmlAttributes, ariaPropsTypes } from '../../../helpers/ariaAttributes';

function FormOptionTab(props) {
  const inputProps = {
    type: (props.canToggle ? 'checkbox' : 'radio'),
    id: props.id,
    name: props.name,
    value: props.value,
    checked: props.checked,
  };

  const modifiers = [
    props.withBullet && 'bulletTab',
    props.size,
    props.shape,
    props.theme,
  ];

  const states = [
    props.checked && 'selected',
    props.disabled && ' disabled',
  ];

  const buttonRef = useRef(null);
  const handleClick = () => {
    const data = {
      target: {
        id: props.id,
        name: props.name,
        value: props.value,
        checked: !props.checked,
      },
    };

    props.onChange(data);

    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };


  const dataset = extractDataAttributes(props.data);

  return (
    <button
      ref={buttonRef}
      className={suitcss({
        componentName: 'FormOptionTab',
        modifiers,
        states,
      })}
      onClick={handleClick}
      {...dataset}
    >
      <input
        className={suitcss({
          componentName: 'FormOptionTab',
          descendantName: 'input',
        })}
        {...inputProps}
        onChange={props.onChange}
        {...props.attributes}
        tabIndex="-1"
        {...ariaHtmlAttributes(props)}
        aria-label={props.label}
      />
      <label
        className={suitcss({
          componentName: 'FormOptionTab',
          descendantName: 'label',
        })}
        htmlFor={props.id}
      >
        {props.withBullet && (
          <span
            className={suitcss({
              componentName: 'FormOptionTab',
              descendantName: 'bullet',
            })}
            style={{ backgroundColor: props.value }}
          />
        )}
        {!props.icon && (
          <span
            aria-hidden
            className={suitcss({
              componentName: 'FormOptionTab',
              descendantName: 'labelInner',
            })}
          >
            {props.label}
          </span>
        )}
        {props.icon && (
          <span
            className={suitcss({
              componentName: 'FormOptionTab',
              descendantName: 'labelIcon',
            })}
          >
            <SvgLoader path={props.icon} />
          </span>
        )}
      </label>
    </button>
  );
}

FormOptionTab.propTypes = {
  attributes: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  canToggle: PropTypes.bool,
  disabled: PropTypes.bool,
  withBullet: PropTypes.bool,
  size: PropTypes.oneOf(['', 'large']),
  shape: PropTypes.oneOf(['', 'round']),
  theme: PropTypes.oneOf(['', 'dark']),
  data: PropTypes.object,
  ...ariaPropsTypes,
};

FormOptionTab.defaultProps = {
  label: '',
  checked: false,
  canToggle: false,
  disabled: false,
  modifiers: [],
};

export default FormOptionTab;
